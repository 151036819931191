<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Type de vol</h1>

    <Checkbox
      v-for="(option, index) in options"
      :key="index"
      :id="`theftType${index}`"
      class="mt-5"
      name="theftType"
      :label="option.label"
      :checkedValue="option.value"
      :uncheckedValue="null"
      v-model="theftType"
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, GenericButton } from "@estaly/ui";

export default {
  components: {
    Checkbox,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    componentStep: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
      theftType: null,
      options: [
        { label: "Vol par effraction", value: "Vol par effraction" },
        { label: "Vol par agression", value: "Vol par agression" },
        { label: "Vol suite à un cambriolage", value: "Vol suite à un cambriolage" },
        { label: "Autre", value: "Autre" },
      ],
    };
  },
  watch: {
    theftType(value) {
      if (value) this.formData.set("theft_type", value);
      else this.formData.delete("theft_type");

      this.updateRequiredFieldsFilled();
    },
    currentStep(step, previous) {
      if (step !== this.componentStep) return;
      if (["theft", "partial_theft"].includes(this.formData.get("coverage"))) return;
      // Clean & skip
      this.formData.delete("theft_type");
      previous < step ? this.$emit("submit") : this.$emit("previous");
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("theft_type");
    },
  },
};
</script>
