<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div>
      <h1 class="text-4xl font-heading-1 pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
        {{ $t("Past claims") }}
      </h1>
    </div>
    <div v-if="claims.length !== 0">
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr class="hidden md:table-row">
                    <th
                      scope="col"
                      class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                    >
                      {{ $t("Claim Id") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Nom du produit
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Date de déclaration
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="(claim, id) in claims"
                    :key="id"
                    @click="redirectToClaimDetailsPage(claim.friendlyId)"
                    :class="[getBorderStyle(claim.requiredAction), 'cursor-pointer hover:bg-gray-100']"
                  >
                    <td :class="['whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6']">
                      <div class="flex flex-col">
                        <div class="flex items-center">
                          <span>{{ claim.friendlyId }}</span>
                        </div>
                        <div class="md:hidden font-light">
                          {{ getFormatedDate(claim.filedDate) }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ claim.productTitle }}
                        </div>
                        <div class="mt-2">
                          <span
                            :class="[
                              getClaimStatusBannerStyle(claim.status.color),
                              'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium',
                            ]"
                          >
                            {{ claim.status.text }}
                          </span>
                          <span
                            v-if="claim.requiredAction"
                            class="inline-flex items-center px-3 py-0.5 ml-3 rounded-full text-xs font-medium bg-red-50 text-black"
                          >
                            Une action est requise de votre part
                          </span>
                        </div>
                      </div>
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ claim.productTitle }}
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ getFormatedDate(claim.filedDate) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="claims.length === 0" class="mt-4">
      <FolderAddIcon class="mx-auto h-12 w-12 text-gray-400" />
      <h1 class="mt-1 text-sm text-gray-500 text-center">
        {{ $t("No claims yet") }}
      </h1>
    </div>
  </div>
</template>

<script>
import { FolderAddIcon } from "@heroicons/vue/solid";

export default {
  components: {
    FolderAddIcon,
  },

  props: ["claims"],

  methods: {
    getClaimStatusBannerStyle(color) {
      return `bg-${color}-100 text-${color}-800 bg-yellow-50`;
    },

    redirectToClaimDetailsPage(friendlyId) {
      this.$router.push(`/customer/claims/${friendlyId}`);
    },

    getFormatedDate(dateToFormat) {
      const currentLocale = this.$i18n.locale;
      return dateToFormat !== null ? new Date(dateToFormat).toLocaleDateString(currentLocale) : null;
    },

    getBorderStyle(isActionRequired) {
      return isActionRequired ? "border-l-2 border-yellow-400 bg-yellow-50" : "";
    },
  },
};
</script>
