<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">
      Le sinistre a-t-il eu lieu à votre domicile ?
    </h1>

    <Checkbox
      id="occurredAtHome"
      class="mt-5"
      name="occurredAtHome"
      label="Oui"
      :checkedValue="true"
      v-model="occurredAtHome"
    />
    <Checkbox
      id="occurredElseWhere"
      class="mt-3"
      name="occurredElseWhere"
      label="Non"
      :checkedValue="false"
      v-model="occurredAtHome"
    />

    <FormField
      v-if="occurredAtHome === false"
      id="occurrencePlace"
      name="occurrencePlace"
      class="mt-5"
      v-model="occurrencePlace"
      label="Où le sinistre a-t-il eu lieu ?"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, FormField, GenericButton } from "@estaly/ui";

export default {
  components: {
    Checkbox,
    FormField,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      occurredAtHome: null,
      requiredFieldsFilled: false,
      occurrencePlace: null,
    };
  },
  watch: {
    occurredAtHome(value) {
      this.formData.set("occurred_at_home", value);
      if (value === "true") this.formData.delete("occurrence_place");
      this.updateRequiredFieldsFilled();
    },
    occurrencePlace(value) {
      this.formData.set("occurrence_place", value);
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled =
        this.occurredAtHome !== null && (this.occurredAtHome || this.formData.get("occurrence_place"));
    },
  },
};
</script>
