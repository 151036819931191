<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Description</h1>

    <Textarea
      id="description"
      class="mt-5"
      v-model="description"
      label="Pouvez-vous décrire précisément ce qu’il s’est passé ?"
      required
    />
    <span class="text-sm text-gray-500">Minimum 50 caractères</span>

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Textarea, GenericButton } from "@estaly/ui";

export default {
  components: {
    Textarea,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
      description: null,
    };
  },
  watch: {
    description(value) {
      this.formData.set("description", value);
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("description") && this.formData.get("description").length >= 50;
    },
  },
};
</script>
