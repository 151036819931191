<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">
      {{ $t("File a claim") }}
    </h1>

    <p class="mt-5">Un pépin est arrivé à votre produit ? Compléter le formulaire de déclaration de sinistre.</p>

    <FormField
      v-if="!isPresent(planSaleOptions)"
      id="planSaleCustomId"
      class="mt-5"
      v-model="planSaleCustomId"
      label="Numéro de contrat"
      name="planSaleCustomId"
      required
    />
    <Selector
      v-else
      id="planSaleCustomId"
      label="Numéro de contrat"
      name="planSaleCustomId"
      v-model="planSaleCustomId"
      :options="planSaleOptions"
      required
    />
    <Alert v-if="!isPresent(currentUser)">
      {{
        $t(
          "You can find your Plan ID (ex : CO12BI250NTA-SHOP-1) in your confirmation email or in your subscription form.",
        )
      }}
    </Alert>

    <FormField id="email" class="mt-5" v-model="email" label="Adresse email" name="email" required />

    <p class="mt-5">
      Retrouvez ces informations dans le mail de confirmation envoyé par Estaly au moment de l'adhésion au contrat.
    </p>

    <div class="mt-5 flex justify-between gap-5">
      <div class="flex-1">
        <GenericButton @click="$emit('submit')" no-scaling :disabled="!requiredFieldsFilled" class="w-full">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, FormField, GenericButton, Selector } from "@estaly/ui";
import { mapState } from "vuex";
import { isPresent } from "@/utils/validation";

export default {
  components: {
    Alert,
    FormField,
    GenericButton,
    Selector,
  },
  props: {
    formData: {
      type: Object,
      required: false,
    },
  },
  emits: ["submit"],
  mounted() {
    this.email = this.currentUser?.email || this.$route.query.email;
    this.planSaleCustomId = this.$route.query.plan;
    if (this.planSaleOptions.length === 1) {
      this.planSaleCustomId = this.planSaleOptions[0].value;
    }
  },
  data() {
    return {
      skipped: false,
      email: null,
      planSaleCustomId: null,
      requiredFieldsFilled: false,
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState("customer", ["plans"]),

    planSaleOptions() {
      return this.plans?.map((plan) => ({
        label: plan.orderId,
        value: plan.orderId, // TODO: This is "customId". To change to friendlyId.
      }));
    },
  },
  watch: {
    email(value) {
      if (value !== undefined) this.formData.set("email", value);
      this.updateRequiredFieldsFilled();
    },
    planSaleCustomId(value) {
      if (value !== undefined) this.formData.set("plan_sale_custom_id", value);
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    isPresent,
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("plan_sale_custom_id") && this.formData.get("email");
    },
  },
};
</script>
