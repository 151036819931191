<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div class="text-center mb-10">
      <GenericButton @onClick="redirectToPreviousPage">
        {{ $t("Back") }}
      </GenericButton>
    </div>

    <div class="flex align-center flex-col">
      <h1 class="text-3xl mb-2 pl-2 font-extrabold tracking-tight text-gray-900 text-center">
        {{ claim.friendlyId }}
      </h1>
      <div class="flex justify-center">
        <span
          :class="[
            getClaimStatusBannerStyle(claim.status.color),
            'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
          ]"
        >
          {{ claim.status.text }}
        </span>
      </div>
    </div>

    <ClaimDeductiblePayment
      v-if="doesClaimExists && claim.isDeductiblePaymentNeeded"
      :payment-link="claim.deductiblePaymentUrl"
    />
    <ClaimHistoricalChanges v-if="doesClaimExists" :claim="claim" class="border border-gray-300 mt-10" />
    <ClaimInformation v-if="doesClaimExists" class="mt-10" :claim="claim" />
    <ClaimFormResponses v-if="doesClaimExists" class="mt-10" :claim="claim" />
    <ClaimNotFound v-if="!doesClaimExists" />
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import ClaimHistoricalChanges from "@/views/customer/claim/details/information/ClaimHistoricalChanges.vue";
import ClaimInformation from "@/views/customer/claim/details/information/ClaimInformation.vue";
import ClaimNotFound from "@/views/customer/claim/details/utils/ClaimNotFound.vue";
import ClaimFormResponses from "@/views/customer/claim/details/information/ClaimFormResponses.vue";
import CustomerService from "@/api/services/customer";
import ClaimDeductiblePayment from "@/views/customer/claim/details/payment/ClaimDeductiblePayment.vue";

export default {
  components: {
    ClaimDeductiblePayment,
    ClaimHistoricalChanges,
    GenericButton,
    ClaimInformation,
    ClaimNotFound,
    ClaimFormResponses,
  },

  data() {
    return {
      claimFriendlyId: "",
      doesClaimExists: false,
      claim: {
        status: {
          text: "",
          color: "",
        },
      },
    };
  },

  created() {
    this.retrieveClaim();
  },

  methods: {
    getClaimStatusBannerStyle(color) {
      return `bg-${color}-100 text-${color}-800`;
    },

    async retrieveClaim() {
      this.claimFriendlyId = this.$route.params.id;
      const response = await CustomerService.getClaimByFriendlyId(this.claimFriendlyId);
      if (response?.success) {
        this.claim = response.data;
        this.doesClaimExists = true;
      } else {
        this.doesClaimExists = false;
      }
    },

    redirectToPreviousPage() {
      this.$router.go(-1);
    },
  },
};
</script>
