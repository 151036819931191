<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <router-link to="/plans" class="ml-1 text-sm font-medium text-primary">
      <div class="flex items-center">
        <ChevronLeftIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
        Mes contrats
      </div>
    </router-link>
    <InsuranceInformation class="mt-6" :plan="plan" />
    <InsuredProductsInformation class="mt-6" :plan="plan" @refreshPlanSale="refreshPlanSale" />
    <PlanSaleDocuments class="mt-6" :plan="plan" />
    <ClaimList class="my-6" :claims="pastClaims" />
  </div>
</template>

<script>
const pages = [
  { name: "Plans", to: "/plans", current: false },
  { name: "Looker Headphones", to: "#", current: true },
];

import { ChevronLeftIcon } from "@heroicons/vue/solid";
import CustomerService from "../api/services/customer";
import { mapState, mapActions } from "vuex";
import ClaimList from "@/components/claim/ClaimList";
import PlanSaleDocuments from "@/components/plan/PlanSaleDocuments.vue";
import InsuranceInformation from "@/views/plan/InsuranceInformation.vue";
import InsuredProductsInformation from "@/views/plan/InsuredProductsInformation.vue";
import { isObjectValid } from "@/utils/validation";

export default {
  components: {
    InsuredProductsInformation,
    InsuranceInformation,
    PlanSaleDocuments,
    ChevronLeftIcon,
    ClaimList,
  },

  created() {
    this.setPlan();
    this.withLoader(this.getClaims);
  },

  data() {
    return {
      plan: {},
      pastClaims: [],
      pages: pages,
      isSelectorOpen: false,
      category: "",
    };
  },

  computed: {
    ...mapState("customer", ["plans"]),
    contractDetailsLink() {
      return this.plan.marketingPage;
    },
  },

  watch: {
    "$route.params.id"(new_value) {
      if (isObjectValid(new_value)) {
        this.setPlan();
      }
    },
  },

  methods: {
    ...mapActions("customer", ["getPlans", "withLoader"]),
    ...mapActions("notifications", ["notify"]),

    setPlan() {
      this.plan = this.plans.filter((plan) => plan.id === parseInt(this.$route.params.id))[0];
      this.withLoader(this.getClaims);
    },

    async getClaims() {
      const response = await CustomerService.getClaimsByPlanSaleId(this.plan.id);
      if (response?.success) this.pastClaims = response.data.claims;
    },

    async refreshPlanSale() {
      const response = await this.getPlans();
      if (response?.success) this.setPlan();
    },
  },
};
</script>
