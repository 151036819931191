import { plainAxiosInstance } from "../axios";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";

class ClaimService {
  newResponse({ planSaleCustomId, email }) {
    return plainAxiosInstance.get("/claims/responses/new", {
      params: toSnakeCase({ planSaleCustomId, email }),
    });
  }

  createResponse(formData) {
    return plainAxiosInstance.post("/claims/responses", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new ClaimService();
