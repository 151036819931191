<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Attestation d’assurance habitation</h1>

    <FileField
      id="home_insurance_certificate"
      class="mt-5"
      :formData="formData"
      @files="updateRequiredFieldsFilled"
      label="Ajoutez une copie de votre attestation d’assurance habitation "
      placeholder="Choisir un fichier"
      required
    />

    <Checkbox
      id="otherCompensation"
      name="otherCompensation"
      class="mt-5"
      label="J'atteste sur l'honneur que je n'ai pas perçu / je ne percevrai pas une autre indemnité pour ce sinistre. Si une indemnité a été perçue, je vous adresse en pièce jointe l'attestation de mon assurance avec le montant indemnisé."
      :checkedValue="'true'"
      :uncheckedValue="'false'"
      v-model="otherCompensation"
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, FileField, GenericButton } from "@estaly/ui";

export default {
  components: {
    Checkbox,
    FileField,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    componentStep: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
      otherCompensation: null,
    };
  },
  watch: {
    otherCompensation(value) {
      this.formData.set("other_compensation", value);
      this.updateRequiredFieldsFilled();
    },
    currentStep(step, previous) {
      if (step !== this.componentStep) return;
      const coverage = this.formData.get("coverage");
      if (["theft", "partial_theft"].includes(coverage)) return;
      // Clean & skip
      this.formData.delete("home_insurance_certificate");
      this.formData.delete("other_compensation");
      previous < step ? this.$emit("submit") : this.$emit("previous");
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled =
        this.formData.get("home_insurance_certificate") && this.formData.get("other_compensation") === "true";
    },
  },
};
</script>
