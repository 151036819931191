<template>
  <div>
    <div>
      <h1 class="text-3xl pb-4 font-extrabold tracking-tight text-gray-900">
        {{ $t("How would you rate your Estaly experience?") }}
      </h1>
    </div>
  </div>
  <div class="pt-8">
    <RadioGroup v-model="selectedFeedback">
      <RadioGroupLabel class="text-base font-medium text-gray-900"></RadioGroupLabel>

      <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
        <RadioGroupOption
          as="template"
          v-for="feedbackList in this.feedbackLists"
          :key="feedbackList.id"
          :value="feedbackList"
          v-slot="{ checked, active }"
        >
          <div
            :class="[
              checked ? 'border-primary' : 'border-gray-300',
              active ? 'border-primary ring-2 ring-primary' : '',
              'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
            ]"
          >
            <span class="flex flex-1">
              <span class="flex flex-col">
                <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">
                  {{ feedbackList.title }}
                </RadioGroupLabel>
              </span>
            </span>
            <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary']" aria-hidden="true" />
            <span
              :class="[
                active ? 'border' : 'border-2',
                checked ? 'border-primary' : 'border-transparent',
                'pointer-events-none absolute -inset-px rounded-lg',
              ]"
              aria-hidden="true"
            />
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </div>
  <div class="pt-8">
    <div>
      <label for="comment" class="block text-sm font-medium text-gray-700">
        {{ $t("Can you tell us a little more about it?") }}
      </label>
      <div class="mt-1">
        <textarea
          rows="4"
          name="comment"
          id="comment"
          v-model="feedbackMessage"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
        />
      </div>
    </div>
  </div>
  <div class="flex justify-between mt-12">
    <button
      type="button"
      class="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
      @click="cancel"
    >
      {{ $t("Undo") }}
    </button>
    <button
      type="button"
      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 text-button-font-primary bg-primary uppercase"
      @click="validateAndCancelPlan"
    >
      {{ $t("Cancel my contract") }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { ref } from "vue";
import { isStringValid } from "@/utils/validation";

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
  },
  mounted() {
    this.selectedFeedback = ref(this.feedbackLists[3]);
  },

  created() {
    this.selectedFeedback = ref(this.feedbackLists[3]);
  },

  props: {
    reason: String,
  },

  data: () => {
    return {
      cancellationDate: "",
      selectedFeedback: "",
      feedbackMessage: "",
      feedbackLists: [
        {
          id: 1,
          title: "Mauvaise",
        },
        {
          id: 2,
          title: "Passable",
        },
        {
          id: 3,
          title: "Bien",
        },
        {
          id: 4,
          title: "Super",
        },
      ],
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    cancel() {
      this.$router.push("/plans");
    },

    validateAndCancelPlan() {
      if (this.validFormFields()) {
        this.$emit("feedbackExperience", this.selectedFeedback.title);
        this.$emit("feedbackMessage", this.feedbackMessage);
        this.$emit("cancelPlan");
      }
    },

    validFormFields() {
      if (this.validFeedbackMessage(this.feedbackMessage)) {
        return true;
      } else {
        this.notify({
          category: "simple",
          type: "error",
          title: "Incomplete feedback message",
          text: "Please provide a feedback message",
        });
        return false;
      }
    },

    validFeedbackMessage(feedbackMessage) {
      return isStringValid(feedbackMessage);
    },
  },
};
</script>
