<template>
  <div class="w-full md:w-2/3 mx-auto">
    <ProgressionBar :max="stepComponents.length" :value="step" />
    <component
      v-for="(stepComponent, index) in stepComponents"
      :key="index"
      :is="stepComponent"
      v-show="step === index + 1"
      :formData="formData"
      :componentStep="index + 1"
      :currentStep="step"
      :products="products"
      :coverages="coverages"
      :contractCategory="contractCategory"
      @submit="submit"
      @previous="step--"
    />
  </div>
</template>

<script>
import Step1 from "@/components/claim/form/Step1.vue";
import Step2 from "@/components/claim/form/Step2.vue";
import Step3 from "@/components/claim/form/Step3.vue";
import Step4 from "@/components/claim/form/Step4.vue";
import Step5 from "@/components/claim/form/Step5.vue";
import Step6 from "@/components/claim/form/Step6.vue";
import Step7 from "@/components/claim/form/Step7.vue";
import Step8 from "@/components/claim/form/Step8.vue";
import Step9 from "@/components/claim/form/Step9.vue";
import Step10 from "@/components/claim/form/Step10.vue";
import Step11 from "@/components/claim/form/Step11.vue";
import Step12 from "@/components/claim/form/Step12.vue";
import Step13 from "@/components/claim/form/Step13.vue";
import Step14 from "@/components/claim/form/Step14.vue";
import Step15 from "@/components/claim/form/Step15.vue";
import ClaimService from "@/api/services/claim";
import { ProgressionBar } from "@estaly/ui";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import { mapActions } from "vuex";

export default {
  components: {
    ProgressionBar,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Step10,
    Step11,
    Step12,
    Step13,
    Step14,
    Step15,
  },
  data() {
    return {
      step: 1,
      stepComponents: [
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
        Step7,
        Step8,
        Step9,
        Step10,
        Step11,
        Step12,
        Step13,
        Step14,
        Step15,
      ],
      formData: new FormData(),
      contractCategory: null,
      coverages: [],
      products: [],
    };
  },
  methods: {
    ...mapActions("notifications", ["notify"]),
    async submit() {
      if (this.step === 1) return await this.newClaimResponse();
      if (this.step === this.stepComponents.length) return await this.submitForm();
      this.step++;
    },
    async newClaimResponse() {
      const response = await ClaimService.newResponse({
        planSaleCustomId: this.formData.get("plan_sale_custom_id"),
        email: this.formData.get("email"),
      });
      if (!response.success) return;

      const { planSaleId, contractCategory, coverages, products } = toCamelCase(response.data);
      this.formData.set("plan_sale_id", planSaleId);
      this.contractCategory = contractCategory;
      this.coverages = coverages;
      this.products = products;
      this.step++;
    },
    async submitForm() {
      const response = await ClaimService.createResponse(this.formData);
      if (!response.success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Your claim has been submitted!",
        text: "It's being reviewed by our team and you will hear back from a representative within 1-2 business days.",
      });
      this.$router.push("/plans");
    },
  },
};
</script>
