<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Que s’est-il passé ?</h1>

    <Checkbox
      v-for="(option, index) in options"
      :key="index"
      :id="`coverage${index}`"
      class="mt-5"
      name="coverage"
      :label="option.label"
      :checkedValue="option.value"
      :uncheckedValue="null"
      v-model="coverage"
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, GenericButton } from "@estaly/ui";

export default {
  components: {
    Checkbox,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    coverages: {
      type: Array,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
      coverage: null,
    };
  },
  computed: {
    options() {
      return this.coverages.map((coverage) => ({
        label: this.$t(`claim.coverage.${coverage}`),
        value: coverage,
      }));
    },
  },
  watch: {
    coverage(value) {
      if (value) this.formData.set("coverage", value);
      else this.formData.delete("coverage");
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("coverage");
    },
  },
};
</script>
