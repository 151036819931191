<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Renseignez vos informations personelles</h1>

    <FormField id="firstName" name="firstName" class="mt-5" v-model="firstName" label="Prénom" required />
    <FormField id="lastName" name="lastName" class="mt-5" v-model="lastName" label="Nom" required />
    <FormField id="phone" name="phone" class="mt-5" v-model="phone" label="Numéro de téléphone" required />
    <FormField
      id="birthDate"
      name="birthDate"
      type="date"
      class="mt-5"
      v-model="birthDate"
      label="Date de naissance"
      required
    />
    <FileField
      id="id_card"
      class="mt-5"
      :formData="formData"
      @files="updateRequiredFieldsFilled"
      label="Copie de carte nationale d'identité"
      placeholder="Choisir un fichier"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling :disabled="!requiredFieldsFilled" class="w-full">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { FormField, FileField, GenericButton } from "@estaly/ui";

export default {
  components: {
    FormField,
    FileField,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      firstName: null,
      lastName: null,
      phone: null,
      birthDate: null,
      requiredFieldsFilled: false,
    };
  },
  watch: {
    firstName(value) {
      this.formData.set("first_name", value);
      this.updateRequiredFieldsFilled();
    },
    lastName(value) {
      this.formData.set("last_name", value);
      this.updateRequiredFieldsFilled();
    },
    phone(value) {
      this.formData.set("phone", value);
      this.updateRequiredFieldsFilled();
    },
    birthDate(value) {
      this.formData.set("birth_date", value);
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled =
        this.formData.get("first_name") &&
        this.formData.get("last_name") &&
        this.formData.get("phone") &&
        this.formData.get("birth_date") &&
        this.formData.get("id_card");
    },
  },
};
</script>
