<template>
  <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Réponses au formulaire de déclaration de sinistre</h3>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
          v-for="question in questions"
          :key="question.id"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ question.content }}
          </dt>
          <dd v-if="question.type === 'multiple_file'" class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li v-for="file in question.answer" :key="file.name" class="pl-3 pr-4 py-3 flex justify-between text-sm">
                <div class="w-0 flex-1 flex">
                  <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-2 flex-1 w-0 truncate">{{ file.name }}</span>
                  <a
                    class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-button-font-primary bg-primary"
                    :href="file.link"
                    target="_blank"
                  >
                    <EyeIcon class="h-5 w-5" aria-hidden="true" />
                  </a>
                </div>
              </li>
            </ul>
          </dd>
          <dd v-else class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p v-if="$te(`claim.coverage.${question.answer}`)">{{ $t(`claim.coverage.${question.answer}`) }}</p>
            <p v-else>{{ question.answer }}</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>
<script>
import { EyeIcon, PaperClipIcon } from "@heroicons/vue/solid";

export default {
  components: {
    PaperClipIcon,
    EyeIcon,
  },
  props: ["claim"],
  computed: {
    questions() {
      return Object.values(this.claim.declaration).filter(
        (question) =>
          question.content !== "Déclaration complétée" && question.content !== "Condition autre indemnisation acceptée",
      );
    },
  },
};
</script>
