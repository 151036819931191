<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Facture d’achat du produit</h1>

    <Alert class="mt-5">
      Le ticket de caisse n’est pas une facture. Si vous n’êtes pas en possession de la facture, merci de la demander au
      marchand.
    </Alert>
    <FileField
      id="product_invoice"
      class="mt-5"
      :formData="formData"
      @files="updateRequiredFieldsFilled"
      label="Ajoutez la facture d’achat de votre produit"
      placeholder="Choisir un fichier"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, FileField, GenericButton } from "@estaly/ui";

export default {
  components: {
    Alert,
    FileField,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
    };
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("product_invoice");
    },
  },
};
</script>
