import { plainAxiosInstance } from "../axios";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";

class LeadService {
  get(uniqueToken) {
    return plainAxiosInstance.get(`/public/leads/${uniqueToken}`);
  }

  update(uniqueToken, params) {
    return plainAxiosInstance.put(`/public/leads/${uniqueToken}`, toSnakeCase(params));
  }

  create(params) {
    return plainAxiosInstance.post(`/public/leads`, params);
  }

  getMarketingDetails(productCategory, storeShopName) {
    return plainAxiosInstance.get(
      `public/stores/marketings?category=${productCategory}&store_shop_name=${storeShopName}`,
    );
  }

  getStore(storeShopName) {
    return plainAxiosInstance.get(`/public/stores/${storeShopName}`);
  }
}

export default new LeadService();
