<template>
  <span :class="[this.styleToAdd, 'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium']">
    {{ $t(capitalizeFirstLetter(`${this.value}`)) }}
  </span>
</template>

<script>
import { capitalizeFirstLetter } from "@/utils/utils";

export default {
  props: ["styleToAdd", "value"],
  methods: {
    capitalizeFirstLetter(string) {
      return capitalizeFirstLetter(string.replace(/_/g, " "));
    },
  },
};
</script>
