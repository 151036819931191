<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Facture d’achat de l’antivol agréé</h1>

    <FileField
      id="anti_theft_device_invoice"
      class="mt-5"
      :formData="formData"
      @files="updateRequiredFieldsFilled"
      label="Ajoutez la facture d’achat de l’antivol agréé"
      placeholder="Choisir un fichier"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { FileField, GenericButton } from "@estaly/ui";

export default {
  components: {
    FileField,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    componentStep: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    contractCategory: {
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
    };
  },
  watch: {
    currentStep(step, previous) {
      if (step !== this.componentStep) return;
      if (
        ["bikes", "edpm", "bikes_simple", "bikes_premium"].includes(this.contractCategory) &&
        ["theft", "partial_theft"].includes(this.formData.get("coverage"))
      ) {
        return;
      }
      // Clean & skip
      this.formData.delete("anti_theft_device_invoice");
      previous < step ? this.$emit("submit") : this.$emit("previous");
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("anti_theft_device_invoice");
    },
  },
};
</script>
