<template>
  <ClaimForm />
</template>

<script>
import ClaimForm from "@/components/claim/form/ClaimForm.vue";

export default {
  components: {
    ClaimForm,
  },
};
</script>
