<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Renseignez votre adresse postale</h1>
    <p>Cette adresse sera utilisée pour l'envoi de votre produit en cas de remplacement ou de réparation.</p>

    <div class="md:flex justify-between gap-5">
      <FormField id="streetNumber" name="streetNumber" class="mt-5" v-model="streetNumber" label="Numéro" required />
      <div class="flex-1 md:pl-2">
        <FormField id="street" name="street" class="mt-5" v-model="street" label="Rue" required />
      </div>
    </div>
    <div class="md:flex justify-between gap-5">
      <FormField id="zipCode" name="zipCode" class="mt-5" v-model="zipCode" label="Code postal" required />
      <div class="flex-1 md:pl-2">
        <FormField id="city" name="city" class="mt-5" v-model="city" label="Ville" required />
      </div>
    </div>
    <Selector
      id="country"
      label="Pays"
      name="country"
      class="mt-5"
      v-model="country"
      :options="countryOptions"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { FormField, GenericButton, Selector } from "@estaly/ui";
import countries from "@estaly/ui/src/utils/countries";

export default {
  components: {
    FormField,
    GenericButton,
    Selector,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
      streetNumber: null,
      street: null,
      zipCode: null,
      city: null,
      country: null,
    };
  },
  computed: {
    countryOptions() {
      return countries.map((country) => ({
        label: country.name,
        value: country.code,
      }));
    },
  },
  watch: {
    streetNumber(value) {
      this.formData.set("street_number", value);
      this.updateRequiredFieldsFilled();
    },
    street(value) {
      this.formData.set("street", value);
      this.updateRequiredFieldsFilled();
    },
    zipCode(value) {
      this.formData.set("zip_code", value);
      this.updateRequiredFieldsFilled();
    },
    city(value) {
      this.formData.set("city", value);
      this.updateRequiredFieldsFilled();
    },
    country(value) {
      this.formData.set("country", value);
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled =
        this.formData.get("street_number") &&
        this.formData.get("street") &&
        this.formData.get("zip_code") &&
        this.formData.get("city") &&
        this.formData.get("country");
    },
  },
};
</script>
