<template>
  <div>
    <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Mot de passe</h3>
          <p class="mt-1 text-sm text-gray-500">
            Modifiez le mot de passe que vous utilisez pour vous connecter à Estaly.
          </p>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div" class="w-full" ref="form">
            <form @submit.prevent="handleSubmit($event, handleUpdatePassword)">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label for="old-password" class="block text-sm font-medium text-gray-700">Ancien mot de passe</label>
                  <Field
                    name="oldPassword"
                    v-model="oldPassword"
                    id="old-password"
                    type="password"
                    class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="oldPassword" class="mt-2 text-sm text-red-600" />
                </div>
                <div class="col-span-6 sm:col-span-3"></div>
                <div class="col-span-6 sm:col-span-3">
                  <label for="new-password" class="block text-sm font-medium text-gray-700">Nouveau mot de passe</label>
                  <Field
                    name="newPassword"
                    v-model="newPassword"
                    id="new-password"
                    type="password"
                    class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="newPassword" class="mt-2 text-sm text-red-600" />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label for="new-password-confirmation" class="block text-sm font-medium text-gray-700">
                    Confirmation nouveau mot de passe
                  </label>
                  <Field
                    type="password"
                    name="newPasswordConfirmation"
                    v-model="newPasswordConfirmation"
                    id="new-password-confirmation"
                    class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="newPasswordConfirmation" class="mt-2 text-sm text-red-600" />
                </div>
              </div>
              <div class="flex justify-end mt-6">
                <button
                  type="submit"
                  class="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm text-button-font-primary bg-primary uppercase"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../api/services/user";
import { mapActions } from "vuex";

export default {
  components: { Field, VeeForm, ErrorMessage },

  data: () => {
    const schema = yup.object({
      oldPassword: yup.string().required("Please enter your old password").min(8, "Your old password is too short"),
      newPassword: yup
        .string()
        .notOneOf([yup.ref("oldPassword"), ""], "Your new password must be different")
        .required("Please enter your password")
        .min(8, "Your new password is too short"),
      newPasswordConfirmation: yup
        .string()
        .oneOf([yup.ref("newPassword"), ""], "Passwords must match")
        .required("Please confirm your new password"),
    });

    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      schema,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async handleUpdatePassword() {
      const params = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        newPasswordConfirmation: this.newPasswordConfirmation,
      };
      const { success } = await UserService.updatePassword(params);
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Your password have been changed!",
      });
      this.$refs.form.resetForm();
    },
  },
};
</script>
