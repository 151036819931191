<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <img class="h-8 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="(item, idx) in authorizedNavigationItems"
                  :key="idx"
                  :to="item.to"
                  :class="[
                    this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '',
                    'group flex items-center px-2 py-1.5 text-sm rounded-md text-gray-500',
                  ]"
                >
                  <component
                    :is="item.icon"
                    class="mr-4 flex-shrink-0 h-4 w-4 text-gray-500"
                    :class="[this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '']"
                    aria-hidden="true"
                  />
                  {{ $t("Navigation." + item.name) }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-5 overflow-y-auto bg-white">
        <div class="flex items-center flex-shrink-0 px-4">
          <img class="h-10 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
        </div>
        <div class="mt-5 flex-1 flex flex-col">
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <div v-for="(item, idx) in authorizedNavigationItems" :key="idx">
              <router-link
                :to="item.to"
                :class="[
                  this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '',
                  'group flex items-center px-2 py-1.5 text-sm hover:bg-gray-200 rounded-md text-gray-500',
                ]"
              >
                <component
                  :is="item.icon"
                  class="mr-3 flex-shrink-0 h-4 w-4 text-gray-500"
                  :class="[this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '']"
                  aria-hidden="true"
                />
                {{ $t("Navigation." + item.name) }}
              </router-link>
              <div v-if="item.children" class="space-y-1">
                <div class="space-y-1">
                  <div>
                    <router-link
                      v-for="(subItem, childrenIdx) in item.children"
                      :key="childrenIdx"
                      :to="subItem.to"
                      :class="[
                        'hover:bg-primary-light',
                        'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-primary-dark rounded-md',
                      ]"
                    >
                      {{ subItem.name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div class="z-30 sticky top-0 flex-shrink-0 flex h-16 bg-white shadow">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex"></div>
          <div class="ml-4 flex items-center md:ml-6">
            <div class="mr-6"></div>
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full">
                  <span class="sr-only">Open user menu</span>
                  <div
                    class="h-10 w-10 rounded-full bg-primary-lighter hover:bg-primary flex items-center justify-center"
                  >
                    <p class="text-white">{{ currentUser.initials }}</p>
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      to="/account"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      Account
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <span
                      @click="handleSignout"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      Sign out
                    </span>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main>
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <router-view></router-view>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  CogIcon,
  ArchiveIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
  CloudUploadIcon,
  ViewListIcon,
  CreditCardIcon,
} from "@heroicons/vue/outline";

import { SearchIcon } from "@heroicons/vue/solid";
import { mapActions, mapGetters, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { isObjectValid } from "@/utils/validation";
import { initIntercom } from "@/utils/intercom.js";

const navigation = [
  {
    name: "Contracts",
    to: "/plans",
    icon: DocumentTextIcon,
  },
  {
    name: "File a claim",
    to: "/customer/claims",
    icon: ShieldCheckIcon,
  },
  {
    name: "Claim List",
    to: "/customer/claims/list",
    icon: ViewListIcon,
  },
  {
    name: "Invoices",
    to: "/customer/invoices",
    icon: ArchiveIcon,
  },
  {
    name: "Payment Methods",
    to: "/customer/payment-methods",
    icon: CreditCardIcon,
    permission: "payment_methods",
  },
  {
    name: "Account",
    to: "/account",
    icon: CogIcon,
  },
];

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    CloudUploadIcon,
    Loading,
    ArchiveIcon,
    CogIcon,
    ViewListIcon,
    ShieldCheckIcon,
    DocumentTextIcon,
    CreditCardIcon,
  },

  data: () => {
    return {
      navigation,
      sidebarOpen: false,
    };
  },

  watch: {
    $route() {
      this.updateNavigation();
    },
  },

  beforeMount() {
    this.updateNavigation();
  },

  mounted() {
    initIntercom(this.currentUser.email, this.currentUser.intercomUserHash, this.currentUser.firstName);
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState("customer", ["plans"]),
    ...mapState("navigation", ["activePage"]),
    ...mapGetters("navigation", ["isCurrentActivePage"]),
    ...mapGetters("auth", ["currentUserHasPermission"]),

    authorizedNavigationItems() {
      return this.navigation.filter((navigationItem) => this.currentUserHasPermission(navigationItem.permission));
    },
  },

  methods: {
    ...mapActions("auth", ["signout"]),
    ...mapActions("notifications", ["notify"]),
    ...mapActions("store", ["reinitializeStoreState"]),
    ...mapActions("customer", ["reinitializeCustomerState"]),
    ...mapActions("navigation", ["updateActivePage", "resetActivePage"]),

    async handleSignout() {
      const { success } = await this.signout();
      if (!success) return;

      this.$router.push("/signin");
      this.notify({
        category: "simple",
        type: "success",
        title: "You successfully signed out.",
      });
      this.reinitializeStoreState();
      this.reinitializeCustomerState();
    },

    updateNavigation() {
      const activePage = isObjectValid(this.$route.meta)
        ? navigation.find((item) => item["name"] === this.$route.meta.navigation)
        : null;

      if (isObjectValid(activePage)) {
        this.updateActivePage(activePage);
      } else {
        this.resetActivePage();
      }
    },
  },
};
</script>
