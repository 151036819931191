<template>
  <div>
    <div>
      <h1 class="text-3xl pb-4 font-extrabold tracking-tight text-gray-900">
        {{ $t("Contract cancellation") }}
      </h1>
    </div>
  </div>
  <div>
    <p class="text-lg font-light text-gray-600">
      {{ $t("We are sad to see you leave, can you tell us a little more about the reasons for this change?") }}
    </p>
  </div>
  <div class="pt-8 grid grid-cols-1">
    <div class="mr-0 sm:mr-4">
      <Listbox as="div" v-model="selectedReason">
        <ListboxLabel for="cancellationReason" class="block text-sm font-medium text-gray-700 mb-2">
          {{ $t("Reason") }}
        </ListboxLabel>
        <div class="relative">
          <ListboxButton
            class="rounded-lg relative cursor-pointer border bg-white py-2 pl-3 text-left focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm w-full"
          >
            <div class="items-center">
              <div>
                <span class="font-medium text-primary" v-if="selectedReason">
                  {{ $t("Plan cancellation reasons." + selectedReason.reason) }}
                </span>
                <span class="font-medium text-primary" v-else>
                  {{ $t("Please select an option in the dropdown below") }}
                </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon class="h-5 w-5" style="font-size: 0.5rem" aria-hidden="true" />
                </span>
              </div>
            </div>
          </ListboxButton>

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm cursor-pointer"
            >
              <ListboxOption
                as="template"
                v-for="reason in this.reasons"
                :key="reason.id"
                :value="reason"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'text-button-font-primary bg-primary' : 'text-gray-900',
                    'relative select-none py-2 pl-3 pr-9 cursor-pointer',
                  ]"
                >
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate cursor-pointer']">
                    {{ $t("Plan cancellation reasons." + reason.reason) }}
                  </span>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-button-font-primary bg-primary' : 'text-gray-900',
                      'absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
  </div>
  <div class="flex justify-between mt-12">
    <button
      type="button"
      class="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
      @click="cancel"
    >
      {{ $t("Undo") }}
    </button>
    <button
      type="button"
      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 text-button-font-primary bg-primary uppercase"
      @click="nextStep"
    >
      {{ $t("Next") }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { isObjectValid } from "@/utils/validation";
import PlanService from "@/api/services/plan.js";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
  },

  mounted() {
    this.cancellationDate = this.getCurrentDate();
  },

  async created() {
    this.reasons = await this.formattedCancellationReasons();
  },

  data: () => {
    return {
      cancellationDate: "",
      selectedReason: null,
      reasons: [],
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    getCurrentDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //Les mois sont de 0 à 11 donc on rajoute 1
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    nextStep() {
      if (this.validFormFields()) {
        this.$emit("next");
        this.$emit("reason", this.selectedReason.reason);
        this.$emit("cancellationDate", this.cancellationDate);
      }
    },

    validFormFields() {
      if (this.validCancellationReason(this.selectedReason)) {
        return true;
      } else {
        this.notify({
          category: "simple",
          type: "error",
          title: "Cancellation reason missing",
          text: "Please provide a cancellation reason",
        });
        return false;
      }
    },

    cancel() {
      this.$router.push("/plans");
    },

    validCancellationReason(reason) {
      return isObjectValid(reason);
    },

    async formattedCancellationReasons() {
      const reasons = await this.fetchCancellationReasons();

      return reasons.map((reason, i) => {
        return { id: i, reason };
      });
    },

    async fetchCancellationReasons() {
      const response = await PlanService.getCancellationReasons();
      if (response?.success) {
        return response.data;
      } else {
        return ["I have no particular reason"];
      }
    },
  },
};
</script>
