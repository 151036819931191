<template>
  <div class="flex h-screen">
    <div class="m-auto">
      <img class="h-12 w-auto m-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly logo" />
      <div class="mt-4 flex" v-if="loading">
        <Spinner />
        <p class="text-green-600" v-if="success">
          {{ $t("Deductible payment found, redirecting to our partner Stripe...") }}
        </p>
        <p v-else>{{ $t("Deductible payment loading") }}</p>
      </div>
      <div class="mt-4" v-if="errorMessage">
        <p class="text-red-600">{{ $t(errorMessage) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from "@/api/services/customer";
import { Spinner } from "@estaly/ui";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      encryptedId: null,
      loading: true,
      errorMessage: null,
      stripeUrl: null,
      success: false,
    };
  },

  mounted() {
    this.fillEncryptedId();

    // Delay the fetching of the url to let the user read the message before being redirected
    setTimeout(this.fetchStripePaymentUrl, 2000);
  },

  methods: {
    fillEncryptedId() {
      this.encryptedId = this.$route.query.encrypted_id;
    },

    async fetchStripePaymentUrl() {
      const response = await CustomerService.getClaimDeductiblePaymentStripeUrl(this.encryptedId);
      if (!response?.success) return this.handleErrorMessage(response);

      this.stripeUrl = response.data.url;
      if (this.verifyStripeLink()) {
        this.redirectToStripe();
      }
    },

    verifyStripeLink() {
      // Very basic check, we just want to be sure to redirect the user to Stripe
      return this.stripeUrl.startsWith("https://checkout.stripe.com/");
    },

    redirectToStripe() {
      this.success = true;
      setTimeout(() => {
        window.location.replace(this.stripeUrl);
      }, 2000);
    },

    handleErrorMessage(response) {
      if (response?.status === 404) {
        this.errorMessage = "No deductible found, please verify that the link is correct.";
      } else if (response?.status === 400) {
        this.errorMessage = "No deductible awaiting your action, if you just paid it you can close this window.";
      } else {
        this.errorMessage = "Unknown error, please try again later or contact our support.";
      }
      this.loading = false;
    },
  },
};
</script>
