<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Photos du produit</h1>

    <Alert class="mt-5">
      Les dommages doivent être clairement visibles. Envoyez-nous au minimum une photo mais n'hésitez pas à en ajouter
      d'autres pour aider à la prise en charge de votre sinistre.
    </Alert>

    <FileField
      id="product_photos[]"
      class="mt-5"
      multiple
      :formData="formData"
      @files="updateRequiredFieldsFilled"
      label="Ajoutez une ou plusieurs photos du produit endommagé"
      placeholder="Choisir un fichier"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, FileField, GenericButton } from "@estaly/ui";

export default {
  components: {
    Alert,
    FileField,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    componentStep: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
    };
  },
  watch: {
    currentStep(step, previous) {
      if (step !== this.componentStep) return;
      const coverage = this.formData.get("coverage");
      if (["breakage", "breakdown", "stain", "rips", "burn", "error_margin"].includes(coverage)) return;
      // Clean & skip
      this.formData.delete("product_photos[]");
      previous < step ? this.$emit("submit") : this.$emit("previous");
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("product_photos[]");
    },
  },
};
</script>
