<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Sélectionnez le produit concerné</h1>

    <Selector
      id="productId"
      label="Pour quel produit souhaitez-vous déclarer un sinistre ?"
      name="productId"
      v-model="productId"
      :options="productOptions"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling :disabled="!requiredFieldsFilled" class="w-full">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { GenericButton, Selector } from "@estaly/ui";
import { isPresent } from "@/utils/validation";

export default {
  components: {
    GenericButton,
    Selector,
  },
  props: {
    formData: {
      type: Object,
      required: false,
    },
    componentStep: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  emits: ["submit"],
  mounted() {
    this.productId = this.$route.query.product_id;
  },
  data() {
    return {
      productId: null,
      requiredFieldsFilled: false,
    };
  },
  computed: {
    productOptions() {
      return this.products?.map((product) => ({
        label: product.title,
        value: product.id,
      }));
    },
  },
  watch: {
    productId(value) {
      if (value !== undefined) this.formData.set("product_id", value);
      this.updateRequiredFieldsFilled();
    },
    currentStep(step, previous) {
      if (step !== this.componentStep) return;
      if (this.productOptions.length > 1) return;
      // Clean & skip
      this.formData.delete("product_id");
      previous < step ? this.$emit("submit") : this.$emit("previous");
    },
  },
  methods: {
    isPresent,
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("product_id");
    },
  },
};
</script>
