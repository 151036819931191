<template>
  <div class="flex flex-col gap-y-4 items-stretch mt-6">
    <div class="flex flex-col gap-4 lg:flex-row">
      <div v-if="marketingDetails.break" class="flex relative flex-col-reverse basis-1/2 flex-grow">
        <div class="relative rounded-3xl transform-gpu flex flex-col gap-2 p-4 bg-primary-light">
          <div class="flex flex-col">
            <span class="font-body-bold font-extrabold text-lg">{{ marketingDetails.break.headline }}</span>
            <span class="text-base font-heading-2 tracking-normal uppercase">
              {{ marketingDetails.break.subHeadline }}
            </span>
          </div>
          <div>
            <ul
              class="flex flex-col gap-3 my-1"
              v-for="(bulletPoint, index) in marketingDetails.break.coverageBulletPoints"
              :key="index"
            >
              <li class="flex flex-row gap-3 items-center">
                <div><ShieldCheckIcon class="h-6 w-6" /></div>
                <div>{{ bulletPoint }}</div>
              </li>
            </ul>
            <ul
              class="flex flex-col gap-3 my-1"
              v-for="(bulletPoint, index) in marketingDetails.break.exclusionBulletPoints"
              :key="index"
            >
              <li class="flex flex-row gap-3 items-center">
                <div><XCircleIcon class="h-6 w-6" /></div>
                <div>{{ bulletPoint }}</div>
              </li>
            </ul>
          </div>
          <div class="h-0 border-t border-black"></div>
          <div>
            <div class="flex justify-between items-center">
              <span>{{ marketingDetails.break.deductible.headline }}</span>
              <div class="flex space-x-2 lg:flex-col">
                <span class="font-bold">{{ marketingDetails.break.deductible.cost }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="marketingDetails.theft" class="flex relative flex-col-reverse basis-1/2 flex-grow">
        <div class="relative rounded-3xl transform-gpu flex flex-col gap-2 p-4 bg-primary-light">
          <div class="flex flex-col">
            <span class="font-body-bold font-extrabold text-lg">{{ marketingDetails.theft.headline }}</span>
            <span class="text-base font-heading-2 tracking-normal uppercase">
              {{ marketingDetails.theft.subHeadline }}
            </span>
          </div>
          <div>
            <ul
              class="flex flex-col gap-3 my-1"
              v-for="(bulletPoint, index) in marketingDetails.theft.coverageBulletPoints"
              :key="index"
            >
              <li class="flex flex-row gap-3 items-center">
                <div><ShieldCheckIcon class="h-6 w-6" /></div>
                <div>{{ bulletPoint }}</div>
              </li>
            </ul>
            <ul
              class="flex flex-col gap-3 my-1"
              v-for="(bulletPoint, index) in marketingDetails.theft.exclusionBulletPoints"
              :key="index"
            >
              <li class="flex flex-row gap-3 items-center">
                <div><XCircleIcon class="h-6 w-6" /></div>
                <div>{{ bulletPoint }}</div>
              </li>
            </ul>
          </div>
          <div class="h-0 border-t border-black"></div>
          <div>
            <div class="flex justify-between items-center">
              <span>{{ marketingDetails.theft.deductible.headline }}</span>
              <div class="flex space-x-2 lg:flex-col">
                <span class="font-bold">
                  {{ marketingDetails.theft.deductible.cost }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShieldCheckIcon, XCircleIcon } from "@heroicons/vue/outline";
export default {
  props: {
    marketingDetails: Object,
    productPrice: Number,
  },
  components: {
    ShieldCheckIcon,
    XCircleIcon,
  },
};
</script>
