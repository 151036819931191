<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Quand le sinistre a-t-il eu lieu ?</h1>

    <FormField
      id="occurrenceDate"
      name="occurrenceDate"
      class="mt-5"
      type="date"
      v-model="occurrenceDate"
      label="Date de survenance du sinistre"
      required
    />

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { FormField, GenericButton } from "@estaly/ui";

export default {
  components: {
    FormField,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
      occurrenceDate: null,
    };
  },
  watch: {
    occurrenceDate(value) {
      this.formData.set("occurrence_date", value);
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("occurrence_date");
    },
  },
};
</script>
