<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div>
      <h1 class="text-4xl font-heading-1 pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
        {{ $t("Your invoices") }}
      </h1>
    </div>
    <InvoiceList :planSaleBillings="planSaleBillings" />
    <Pagination :currentPage="currentPage" @changePage="handleChangePage" />
  </div>
</template>

<script>
import CustomerService from "@/api/services/customer";
import { Pagination } from "@estaly/ui";
import InvoiceList from "@/components/InvoiceList.vue";

export default {
  components: {
    InvoiceList,
    Pagination,
  },
  data: () => {
    return {
      currentPage: 1,
      planSaleBillings: [],
    };
  },
  watch: {
    currentPage(newPage) {
      this.retrievePlanSaleBillings(newPage);
    },
  },
  created() {
    this.retrievePlanSaleBillings(this.currentPage);
  },

  methods: {
    async retrievePlanSaleBillings(page) {
      const response = await CustomerService.getPlanSaleBillings(page);
      if (!response?.success) return;

      this.planSaleBillings = response.data.planSaleBillings;
    },

    handleChangePage(page) {
      this.currentPage = page;
    },
  },
};
</script>
