<template>
  <div>
    <h1 class="my-5 text-4xl font-heading-1 tracking-tight text-gray-900">Fin de la déclaration</h1>

    <p>C’est presque fini !</p>

    <Checkbox
      id="declarationCompleted"
      name="declarationCompleted"
      class="mt-5"
      label="Je certifie que les informations fournies dans cette déclaration sont exactes et complètes à ma
              connaissance. Je comprends que toute fausse déclaration ou omission pourrait entraîner l'annulation de ma
              couverture d'assurance ou le refus d'une indemnisation. En cochant cette case, je m'engage à ce que cette
              déclaration soit conforme à la réalité."
      :checkedValue="'true'"
      :uncheckedValue="'false'"
      v-model="declarationCompleted"
    />
    <Alert type="error" class="mt-5">
      <template #icon>🚨</template>
      <p class="text-base">
        Nous rappelons à l'ensemble de nos clients que l'article 313-1 du Code pénal prévoit qu'une fraude à l'assurance
        peut être punie d'une amende allant jusqu’à 375 000 € et d'une peine d'emprisonnement de 5 ans.
      </p>
    </Alert>

    <div class="mt-5 flex justify-between gap-5">
      <GenericButton @click="$emit('previous')" secondary no-scaling class="mr-2">Précédent</GenericButton>
      <div class="flex-1 pl-2">
        <GenericButton @click="$emit('submit')" no-scaling class="w-full" :disabled="!requiredFieldsFilled">
          Suivant
        </GenericButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Alert, GenericButton } from "@estaly/ui";

export default {
  components: {
    Checkbox,
    Alert,
    GenericButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous"],
  data() {
    return {
      requiredFieldsFilled: false,
      declarationCompleted: "false",
    };
  },
  watch: {
    declarationCompleted(value) {
      this.formData.set("declaration_completed", value);
      this.updateRequiredFieldsFilled();
    },
  },
  methods: {
    updateRequiredFieldsFilled() {
      this.requiredFieldsFilled = this.formData.get("declaration_completed") === "true";
    },
  },
};
</script>
